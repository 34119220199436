import { useState } from 'react';
import { useEffect } from 'react';
import { forwardRef } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Recomendations } from './../../components/Recomendations';
import { useReadMonitorRunResultGroupedByMonitorIdQuery } from 'services/monitorRunResult';

type RecommendationListWidgetProps = {
    monitorId?: number,
    monitorLaunchTask?: any,
    onSelectedMonitorLaunchTaskChange?: Function,
}

export const RecommendationListWidget = forwardRef((props: RecommendationListWidgetProps, ref: any): JSX.Element => {
    const {
        monitorId,
        monitorLaunchTask,
        onSelectedMonitorLaunchTaskChange
    } = props;

    const [recomendationList, setRecomendationList] = useState<any | undefined>();
    const [selectedRowId, setSelectedRowId] = useState<number | undefined>();

    const [useReadMonitorRunResultGroupedByMonitorIdQueryParams, setUseReadMonitorRunResultGroupedByMonitorIdQueryParams] = useState<any>({
        monitorId: monitorId
    });

    const [useReadMonitorRunResultGroupedByMonitorIdQueryOptions, setUseReadMonitorRunResultGroupedByMonitorIdQueryOptions] = useState<any>(
        {
            skip: monitorId === undefined,
            refetchOnMountOrArgChange: true,
            pollingInterval: 5000,
        }
    );

    const {
        data: readMonitorRunResultGroupedByMonitorIdData,
        isLoading: readMonitorRunResultGroupedByMonitorIdIsLoading,
        isFetching: readMonitorRunResultGroupedByMonitorIdIsFetching,
        refetch
    } = useReadMonitorRunResultGroupedByMonitorIdQuery(
        useReadMonitorRunResultGroupedByMonitorIdQueryParams,
        useReadMonitorRunResultGroupedByMonitorIdQueryOptions
    );

    const handleItemClick = (params: any) => {
        if (typeof onSelectedMonitorLaunchTaskChange === "function") {
            onSelectedMonitorLaunchTaskChange(params);
        }
    }

    useEffect(() => {
            setUseReadMonitorRunResultGroupedByMonitorIdQueryParams({
                    monitorId: monitorId  
            });

            setUseReadMonitorRunResultGroupedByMonitorIdQueryOptions({
                ...useReadMonitorRunResultGroupedByMonitorIdQueryOptions,
                skip: monitorId === undefined
            });
        },
        [monitorId]
    )

    useEffect(() => {
        if (readMonitorRunResultGroupedByMonitorIdData === undefined) {
            setRecomendationList(null);

            return;
        }

        const recomendations = readMonitorRunResultGroupedByMonitorIdData.list.map((item: any) => {
            const recDirectionToHigh = Math.round(item.recValue) >= item.medianPrice;

            let textRecommendation = `Do nothing`;

            if (item.recDir === 1) {
                textRecommendation = `Set total price to ${recDirectionToHigh ? '≥' : '≤'} $${Math.round(item.recValue)}`;
            }


            if (item.recDir === -1) {
                textRecommendation = `Set total price to ${recDirectionToHigh ? '≥' : '≤'} $${Math.round(item.recValue)}`;
            }

            return {
                id: item.id,
                monitorLaunchTask: item,
                recommendation: {
                    text: textRecommendation,
                    status: item.isRecOn,
                    priceDirection: item.recDir
                },
                date: item.checkinUtc
            };
        });

        setRecomendationList(recomendations);
    },
        [readMonitorRunResultGroupedByMonitorIdData]
    )

    useEffect(() => {
        if (readMonitorRunResultGroupedByMonitorIdData === undefined) {
            return;
        }

        if (monitorLaunchTask === undefined) {


            return;
        }

        setSelectedRowId(monitorLaunchTask.monitorRunsResult.id);
    }, [readMonitorRunResultGroupedByMonitorIdData, monitorLaunchTask])

    return (
        <>
            <Accordion ref={ref} disableGutters defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: '#FFF' }} />}
                    aria-controls="panel1a-content"
                    sx={{
                        backgroundColor: '#B0B0B0',
                        paddingRight: { xs: '12px', md: '24px' },
                        paddingLeft: { xs: '12px', md: '24px' },
                        '& .MuiAccordionSummary-expandIconWrapper': {
                            padding: '12px 12px 12px 12px',
                        },
                        '& .MuiAccordionSummary-content': {
                            flexDirection: {
                                xs: 'column',
                                sm: 'row'
                            }
                        }
                    }}
                >
                    <Typography sx={
                        {
                            fontSize: {
                                xs: '16px',
                                sm: '18px',
                                md: '24px',
                            },
                            lineHeight: '28px',
                            color: '#FFF',
                            marginRight: 2
                        }
                    }
                    >
                        Recomendations
                    </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ bgcolor: '#EEE', p: '16px' }}>
                    <Recomendations
                        onItemClick={handleItemClick}
                        recomendations={recomendationList}
                        selectedRowId={selectedRowId}
                    />
                </AccordionDetails>
            </Accordion>
        </>
    )
})